import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const ColorPalette = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center pd-top-50"></div>
        <h2 className="pd-bottom-50 text-center"> Color Palette</h2>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-md-6 text-center content-directorios">
            <a href="https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf" target="_blank">
              <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2FSW-1.jpg?alt=media&token=288b0d1c-3606-4e99-98a9-5f3539cd0081"></img>
            </a>
          </div>
          <div className="col-lg-1 col-md-6">

          </div>
          <div className="col-lg-3 col-md-6 text-center content-directorios">
            <a href="https://www.benjaminmoore.com/en-us/color-overview/color-palettes" target="_blank">
              <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2Fbjm-1.jpg?alt=media&token=30c85c07-dcc1-4601-81cf-40186bbc014b"></img>
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default ColorPalette;
